<template>
  <div>
    <app-collapse
      accordion
      type="margin"
    >

      <app-collapse-item
        class="text-left"
        title="Schedule Program"
        is-visible="!begin_date"
      >
        <b-row>
          <b-col>
            <b-form-group
              label="Program Begin Date"
              label-for="programStart"
            >
              <flat-pickr
                id="programStart"
                v-model="begin_date"
                :config="{ altInput: true, altFormat: 'F j, Y'}"
                class="form-control"
                placeholder="Begin Date"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Working Hours"
              label-for="workingHours"
            >
              <b-row>
                <b-col>
                  <flat-pickr
                    id="workingHours1"
                    v-model="working_hours[0]"
                    :config="{
                      altInput: true,
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: 'H:i',
                      defaultDate: '09:00'
                    }"
                    class="form-control"
                    placeholder="Start"
                  />
                </b-col>
                <b-col>
                  <flat-pickr
                    id="workingHours2"
                    v-model="working_hours[1]"
                    :config="{
                      altInput: true,
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: 'H:i',
                      minTime: working_hours[0],
                      defaultDate: '20:00'
                    }"
                    class="form-control"
                    placeholder="End"
                  />
                </b-col>
              </b-row>

            </b-form-group>

          </b-col>
          <b-col cols="auto">
            <b-button
              class="mt-2"
              variant="primary"
              @click="update"
            >
              Schedule
            </b-button>
          </b-col>

        </b-row>
      </app-collapse-item>
    </app-collapse>
    <b-overlay
      :show="$apollo.loading || mutationLoading"
      rounded="sm"
      spinner-variant="primary"
    >
      <b-card
        v-if="begin_date"
        class="mt-2"
      >
        <b-card-title
          class="lead"
        >
          Schedule Phases and Activities
        </b-card-title>
        <calendar
          v-if="begin_date"
          :start-date="begin_date"
        />
      </b-card>
    </b-overlay>
  </div>
</template>
<script>
import Calendar from '@/views/incubator-portal/programs/components/calendar/Calendar.vue'
import flatPickr from 'vue-flatpickr-component'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import gql from 'graphql-tag'
import {
  BButton, BCard, BCardTitle, BCol, BFormGroup, BRow,
} from 'bootstrap-vue'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BCard,
    BCardTitle,
    BCol,
    BFormGroup,
    BRow,

    Calendar,
    flatPickr,
    AppCollapse,
    AppCollapseItem,
  },
  props: ['begin_date'],
  data() {
    return {
      mutationLoading: false,
      working_hours: ['09:00', '20:00'],
    }
  },
  methods: {
    update() {
      this.mutationLoading = true
      axiosIns.post('schedule/', {
        program_id: this.$route.params.id,
        p_begin_date: this.begin_date,
        wd_begin_time: `${this.working_hours[0]}:00`,
        wd_end_time: `${this.working_hours[1]}:00`,
      })
        .then(res => this.$toast({
          component: ToastificationContent,
          props: {
            title: `${res.data.message}. Please check after 30 minutes`,
            icon: 'InfoIcon',
          },
        }))
      this.$apollo.mutate({
        // Query
        mutation: gql`mutation {
          update_programs_basicinfo_by_pk(pk_columns: {id: ${this.$route.params.id}}, _set: {begin_date: "${this.begin_date}"}) {
            begin_date
          }
        }`,
        update: () => {
          this.mutationLoading = false
        },
      })
    },
  },
}
</script>
